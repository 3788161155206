<template>
<section class="">
  <link rel="stylesheet" href="https://maxcdn.icons8.com/fonts/line-awesome/1.1/css/line-awesome-font-awesome.min.css">
  <div class="hero-body">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-5-tablet is-4-desktop is-3-widescreen">
          <div class="column">
            <div class="container">
              <figure class="image is-256x256 is-inline-block">
                <img class="" alt="logo" src="../assets/logo.png">
              </figure>
            </div>
          </div>
          <form action class="box column" @submit.prevent="login">
            <div class="field">
              <label class="label" for="#email">User:</label>
              <div class="control has-icons-left">
                <input
                  v-model="email"
                  class="input"
                  type="email"
                  id="email"
                  required
                  placeholder="User"
                >
                <span class="icon is-small is-left">
                  <i class="fa fa-envelope"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label" for="#password">Password:</label>
              <div class="control has-icons-left">
                <input
                  v-model="password"
                  class="input"
                  type="password"
                  id="password"
                  placeholder="Password"
                >
                <span class="icon is-small is-left">
                  <i class="fa fa-lock"></i>
                </span>
              </div>
            </div>
            <div v-if="1!=1" class="field">
              <label for="" class="checkbox">
                <input type="checkbox">
               Recordar
              </label>
            </div>
            <div class="field">  
              <p v-if="error" class="error">{{ this.strError }}</p>
            </div>
            <div class="field">
              <input class="button is-success" type="submit" value="Login">
            </div>
            </form>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import auth from '@/auth/index'

export default {
  data: () => ({
    email: '',
    password: '',
    error: false,
    strError: ''
  }),
  methods: {
    async login () {
      try {
        const result = await auth.login(this.email, this.password)
        auth.setUserLogged(result.data.user, result.data.token)
        this.$router.push('/')
      } catch (error) {
        // console.log(error)
        this.strError = 'Has introducido mal el email o la contraseña.'
        this.error = true
      }
    }
  }
}
</script>
