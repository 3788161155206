<template>
  <LoginComp />
</template>

<script>
// @ is an alias to /src
import LoginComp from '@/components/Login.vue'

export default {
  name: 'LoginView',
  components: {
    LoginComp
  }
}
</script>
